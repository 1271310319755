/**
 * 发现模块接口
 */

import addModular from './../json/addModular'
import getModularList from './../json/getModularList'
import toggleTopArticle from './../json/toggleTopArticle'
import deleteArticle from './../json/deleteArticle'
import getArticleList from './../json/getArticleList'
import getArticleDetail from './../json/getArticleDetail'
import createArticle from './../json/createArticle'
import updateArticle from './../json/updateArticle'
import auditArticle from './../json/auditArticle'

export default [
  {
    // 新增模块
    type: 'post',
    url: 'discover/modular',
    tpl: addModular
  },
  {
    // 获取模块列表
    type: 'GET',
    url: 'discover/modular',
    tpl: getModularList
  },
  {
    // 获取文章列表
    type: 'POST',
    url: 'discover/article/list',
    tpl: getArticleList
  },
  {
    // 获取文章详情
    type: 'GET',
    url: 'discover/article',
    tpl: getArticleDetail
  },
  {
    // 新增文章
    type: 'POST',
    url: 'discover/article',
    tpl: createArticle
  },
  {
    // 保存更新文章
    type: 'PUT',
    url: 'discover/article',
    tpl: updateArticle
  },
  {
    // 删除文章
    type: 'DELETE',
    url: 'discover/article',
    tpl: deleteArticle
  },
  {
    // 审核文章（通过或不通过）
    type: 'POST',
    url: 'discover/article/audit',
    tpl: auditArticle
  },
  {
    // 置顶/取消置顶文章
    type: 'POST',
    url: 'discover/article/top',
    tpl: toggleTopArticle
  }
]
