import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from './components/async_component'

const Home = asyncComponent(() => import('./views/home/index'))
const About = asyncComponent(() => import('./views/about/index'))
const Practice = asyncComponent(() => import('./views/practice/index'))
const Defense = asyncComponent(() => import('./views/defense/index'))

const App = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/about' component={About} />
      <Route path='/practice' component={Practice} />
      <Route path='/defense' component={Defense} />
    </Switch>
  )
}

export default App
